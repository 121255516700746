<template>
  <v-container class="pa-0">
    <div class="pa-2">
      <h5 class="text-uppercase">{{ $t("profilePrivacy.title") }}</h5>
      <div>{{ $t("profilePrivacy.description") }}</div>
      <v-btn
        block
        large
        rounded
        depressed
        color="primary"
        class="my-4"
        @click="openInBrowser('https://www.galasupermercati.it/privacy')"
        >{{ $t("profilePrivacy.title") }}</v-btn
      >
    </div>
    <PrivacySection :userData.sync="userData" />
    <ResponseMessage class="mt-3" :response="response" />
    <div class="px-2 py-3">
      <v-btn
        depressed
        block
        rounded
        color="primary"
        large
        @click="updatePrivacy"
        >{{ $t("common.save") }}</v-btn
      >
    </div>
  </v-container>
</template>
<script>
import PrivacySection from "@/components/profile/form/PrivacySection.vue";
import AbbondanzaRegistrationService from "@/service/abbondanzaRegistrationService";
import ResponseMessage from "@/components/ResponseMessage.vue";

export default {
  components: { PrivacySection, ResponseMessage },
  name: "ProfilePrivacy",
  data() {
    return {
      userData: {},
      response: {}
    };
  },
  methods: {
    reload() {
      AbbondanzaRegistrationService.getPrivacyData().then(data => {
        this.userData = data.value;
      });
    },
    updatePrivacy() {
      AbbondanzaRegistrationService.updatePrivacy(this.userData).then(res => {
        this.response = res.response;
      });
    },
    openInBrowser(url) {
      if (this.$platform_is_cordova && url.indexOf("http") > -1) {
        window.cordova.InAppBrowser.open(url, "_system");
      } else {
        window.open(url, "_blank");
      }
    }
  },
  mounted() {
    this.reload();
  }
};
</script>
